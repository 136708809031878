import { Box, HStack, IconButton, Text, Stack } from "@chakra-ui/react";
import { graphql, Link as GatsbyLink, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC, Fragment } from "react";
import { ShiIcon } from "src/atoms";
import Meta from "src/components/Meta";
import { ImageObjectMarkupType } from "src/components/Meta/Markups/imageObjectMarkup";
import { breadcrumbMarkup } from "src/components/Meta/Markups/utils";
import SiteFooter from "src/components/SiteFooter";
import { linkResolver } from "src/lib/linkResolver";
import SLICES from "src/slices";

import SliceZone from "src/slices/SliceZone";

const PrismicLandingPage: FC<PageProps<Gatsby.PrismicLandingQuery>> = ({
  data: { prismicPageLanding },
}) => {
  if (!prismicPageLanding?.data?.body) throw Error();

  const {
    body,
    indexed,
    partner_logo,
    hide_shine_logo,
    social_media_thumbnail,
    social_media_title,
    meta_description,
    meta_title,
    hotjar
  } = prismicPageLanding.data;

  const path = linkResolver(prismicPageLanding);

  let thumbnailUrl: string | undefined;
  let imageObjectMarkup: ImageObjectMarkupType | undefined;

  if (social_media_thumbnail?.document?.__typename === "PrismicElementImage" && social_media_thumbnail.document.data?.image_file) {
    const { url, dimensions, alt } = social_media_thumbnail.document.data.image_file;
    thumbnailUrl = url;
    imageObjectMarkup = {
      '@id': `${process.env.GATSBY_HOST}/#/schema/page/image/`,
      url,
      contentUrl: url,
      width: String(dimensions?.width),
      height: String(dimensions?.height),
      caption: alt,
    }
  }

  return (
    <Box>
      <Meta
        url={path}
        indexed={indexed}
        title={meta_title}
        metadescription={meta_description}
        socialMediaTitle={social_media_title}
        socialMediaImage={thumbnailUrl}
        hotjar={hotjar}
        {...(path !== '/' ? { breadcrumbMarkup: breadcrumbMarkup({ name: meta_title!, path }) } : {})} 
        {...(imageObjectMarkup ? { imageObjectMarkup } : {})}
        webPageMarkup={{
          name: meta_title,
          description: meta_description,
          thumbnailUrl,
          datePublished: prismicPageLanding.first_publication_date,
          dateModified: prismicPageLanding.last_publication_date,
        }}
      />

      <HStack
        justifyContent="center"
        alignItems="center"
        spacing="space-24"
        width="100%"
        position="absolute"
        top={0}
      >
        {hide_shine_logo ? null : (
          <Fragment>
            <IconButton
              as={GatsbyLink}
              aria-label="Shine logo"
              variant="ghost"
              marginY="auto"
              paddingY="0"
              icon={
                <ShiIcon
                  name="ShineLogo"
                  boxSize="135px"
                />
              }
              to="/"
              _hover={{
                backgroundColor: "initial",
              }}
            />

            {partner_logo?.document?.__typename === "PrismicElementImage" ? (
              <Text as="span">╳</Text>
            ) : null}
          </Fragment>
        )}

        {partner_logo?.document?.__typename === "PrismicElementImage" && (
          <Box
            as={GatsbyImage}
            width="135px"
            image={partner_logo.document.data?.image_file?.gatsbyImageData}
            alt={partner_logo.document.data?.image_file?.alt ?? ""}
          />
        )}
      </HStack>

      <Stack
        width="100%"
        alignItems="center"
        sx={{
          // To keep a place for the partner logo (but inside the first element block, for background-color.)
          "&:nth-of-type(2) > section:first-of-type > :first-of-type": {
            paddingTop: "96px !important",
          },
          ">*:not(style)~*:not(style)": {
            marginInlineStart: "auto",
            marginInlineEnd: "auto",
          },
        }}
      >
        {body && <SliceZone slices={SLICES} body={body} />}
      </Stack>

      <SiteFooter isFull={false} />
    </Box>
  );
};

export default PrismicLandingPage;

export const query = graphql`
  query PrismicLanding($uid: String) {
    prismicPageLanding(uid: { eq: $uid }) {
      type
      uid
      first_publication_date
      last_publication_date

      data {
        partner_logo {
          document {
            __typename
            ... on PrismicElementImage {
              data {
                image_file {
                  gatsbyImageData(width: 135, layout: FIXED)
                  alt
                }
              }
            }
          }
        }
        hide_shine_logo
        indexed
        meta_title
        meta_description
        social_media_thumbnail {
          document {
            __typename
            ...ElementImageUrl
          }
        }
        social_media_title
        hotjar

        body {
          __typename

          ...PrismicSliceMetadata

          ...PageLandingHeader
          ...PageLandingHeaderSpotlight
          ...PageLandingBlockColumns
          ...PageLandingBlockCta
          ...PageLandingBlockRelation
          ...PageLandingBlockAccordion
          ...PageLandingBlockFaq
          ...PageLandingBlockCardsRedirection
          ...PageLandingBlockMatrix
          ...PageLandingBlockProductRecap
          ...PageLandingBlockTestimonial
          ...PageLandingBlockHighlightCards
          ...PageLandingBlockTestimonialCarousel
          ...PageLandingBlockPricingDisplay
          ...PageLandingBlockSteps
          ...PageLandingBlockIllustratedList
          ...PageLandingBlockArticlesCarousel
          ...PageLandingFloatingBanner
          ...PageLandingBlockRichContent
          ...PageLandingPrebuiltBlock
          ...PageLandingBlockBanner
          ...PageLandingBlockVideo
          ...PageLandingBlockPlansPresentation
          ...PageLandingBlockCreationPackagePricing
          ...PageLandingBlockPricingTable
          ...PageLandingSubPages
          ...PageLandingBlockTitle
          ...PageLandingBlockSecurity
          ...PageLandingBlockPersona
          ...PageLandingBlockCreationRedirection
          ...PageLandingBlockMission
          ...PageLandingBlockTrioProductHighlight
          ...PageLandingBlockTetrisProductHighlight
          ...PageLandingBlockFeatureCarousel
          ...PageLandingBlockCenter
          ...PageLandingBlockAnimatedTestimonial
          ...PageLandingBlockNumber
          ...PageLandingBlockHubspotForm
          ...PageLandingBlockEmbedHubspotForm
          ...PageLandingBlockFilterableList
          ...PageLandingBlockTrioOffer
          ...PageLandingBlockHoverableCards
          ...PageLandingBlockScrollableCards
          ...PageLandingBlockStretchableCards
          ...PageLandingBlockAnimation
          ...PageLandingBlockPricingLight
          ...PageLandingBlockProofHeadband
          ...PageLandingBlockUSP
        }
      }
    }
  }
`;
